import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default class {
	constructor() {
		this.run()
	}

	run() {

		let circles = [...document.querySelectorAll('.circles__dottedline')]
		circles.length && circles.forEach(el => {
			gsap.from(el, {
				scrollTrigger: {
					trigger: el,
					once: true,
				},
				alpha: 0,
				scale: 0,
				duration: 1,
				ease: 'expo',
				stagger: 0.1,
			})
		})

		
		let points = [...document.querySelectorAll('.js-point')]
		points.length && points.forEach(el => {
			let num = el.querySelector('[data-num]')
			const curVals = { val: 0 }

			gsap.timeline({
				defaults: {
					immediateRender: true,
					ease: 'expo',
					duration: 4,
				},
				scrollTrigger: {
					trigger: el,
					once: true,
				},
			})
			.to(curVals, {
				val: parseInt(num.dataset.num),
				stagger: 0.2,
				snap: {
					val: 1
				},
				onUpdate: () => {
					num.innerHTML = curVals.val
				}
			})
			.from(el, {
				alpha: 0,
				yPercent: 10,
				stagger: 0.2
			}, 0)
		})

	}
}