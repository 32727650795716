(function($R)
{
    $R.add('plugin', 'glossmodal', {

        // construct
        init: function(app)
        {
            // define redactor app
            this.app = app;

            // your code
        }
    });
})(Redactor);