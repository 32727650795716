import { gsap } from 'gsap'
import { evt, u, store } from '@js/app/index'

const { dom } = store
const { qs, qsa, rect } = u

export default class {

  constructor(el) {
    this.el = el
    this.container = this.el.closest('[data-router-view]')

    this.state = {
      first: false,
      open: false,
    }

    evt.on('click', this.el, this.toggle)
    evt.on('scroll', this.position)
  }

  toggle = () => {
    const state = this.state

    !state.open
      ? (!state.first ? this.create() : this.open())
      : this.close()
  }

  create() {
    if (this.state.first) return
    this.state.first = true

    this.tl = gsap.timeline({ paused: true })

    this.modal = document.createElement('div')
    this.modal.classList.add('gloss-modal')

    this.modal.innerHTML = `
      <div class="gloss-modal-title">${this.el.textContent}</div>
      <p class="gloss-modal-desc">${this.el.dataset.gloss}</p>
    `  

    this.btn = document.createElement('div')
    this.btn.classList.add('gloss-modal-close')

    this.modal.appendChild(this.btn)
    this.container.appendChild(this.modal)

    evt.on('click', this.btn, this.close)
    
    this.open()
  }

  open = () => {
    this.state.open = true
    this.position()

    this.tl.clear()
    .fromTo(this.modal, {
      autoAlpha: 0,
    }, {
      autoAlpha: 1,
      duration: .35,
      ease: 'power1'
    }, 0)
    .fromTo(this.modal, {
      y: '3rem',
    }, {
      y: 0,
      duration: .75,
      ease: 'expo'
    }, 0).play()
  }

  close = () => {
    this.state.open = false

    this.tl.clear()
    .to(this.modal, {
      autoAlpha: 0,
      duration: .35,
      ease: 'power1'
    }, 0).play()
  }

  position = () => {
    if (!this.state.open) return

    const { top, height } = rect(this.el)

    gsap.set(this.modal, {
      top: top + height + 30
    })
  }

  destroy() {
    evt.off('click', this.el, this.toggle)
    evt.off('scroll', this.position)

    this.state.first 
      && (evt.off('click', this.btn, this.close), this.modal.remove())
  }
}
