import Swiper, { EffectFade } from 'swiper'
import { u, store, evt } from '@js/app/index'
import 'swiper/components/effect-fade/effect-fade.scss';

Swiper.use([EffectFade]);

const { dom, flags } = store
const { qs, qsa, rect } = u

export default class {
	constructor(el) {
		this.el = el

		this.init()
	}

	destroy() {
		this.swiper.destroy()
	}

	init() {
  		this.swiper = new Swiper(this.el, {
			observer: true,
            observeParents: true,
            parallax:true,
			autoplay: {
				delay: 2500,
				disableOnInteraction: false,
			},
			fadeEffect: { crossFade: true },
			slidersPerView: 1,
			loop: true,
			effect: 'fade',
  		})

  		evt.on('click', '.swiper-arrow--left', () => {
  			this.swiper.slidePrev()
  		})
  		evt.on('click', '.swiper-arrow--right', () => {
  			this.swiper.slideNext()
  		})
	}
}

