import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { evt, u, store } from '@js/app/index'

gsap.registerPlugin(ScrollTrigger)

export default class {
	constructor(elems) {

		/**
		 * DOM Elements
		 */
		this.DOM = {
			elems: elems,
			btn: [...u.qsa('[data-modal]')],
			overlay: [...u.qsa('.video__overlay')],
			close: [...u.qsa('.js-video-close')],
		}

		this.times = 0
		this.playY = null

		/**
		 * Animation Timeline
		 */
		this.tl = null

		/**
		 * Init
		 */
		this.init()
	}

	closeVideo = (el) => {
		const { elems } = this.DOM

		const video = u.qs('iframe', el)
		const videoInner = u.qs('.video__inner', el)
		const overlay = u.qs('.video__overlay', el)
		const close = u.qs('.js-video-close', el)

		gsap.killTweensOf([videoInner, overlay, close])

		store.dom.body.classList.remove('oh')

		if (video) {
			const iframeSrc = video.src
			video.src = iframeSrc

			this.playY = this.playY.slice(0, -11);
			video.src = this.playY
			this.times = 0
		}

		gsap
		.timeline()
		.to(videoInner, {
			duration: 1,
			scale: 0.75,
			ease: 'expo',
			alpha: 0,
		},0)
		.to(close, {
			duration: 1,
			alpha: 0,
			ease: 'expo'
		}, 0)
		.to(overlay, {
			duration: 1,
			alpha: 0,
			ease: 'expo',
			onComplete: () => {
				// Remove Classes
				el.classList.remove('is-active')
			}
		}, 0)
		
	}

	playVideo = (currentVideo) => {
		const video = u.qs('iframe', currentVideo)
		const videoInner = u.qs('.video__inner', currentVideo)
		const overlay = u.qs('.video__overlay', currentVideo)
		const close = u.qs('.js-video-close', currentVideo)

		store.dom.body.classList.add('oh')

		// Add Class
		currentVideo.classList.add('is-active')

		// Play Video
		gsap.killTweensOf([currentVideo, videoInner, overlay, close])

		if ( this.times == 0) {
			this.playY = video.src += '?autoplay=1'
			this.times = 1
		}

		gsap
		.timeline()
		.fromTo(videoInner, {
			scale: 0.75,
			alpha: 0
		}, {
			alpha: 1,
			duration: 1,
			scale: 1,
			ease: 'expo',
		})
		.fromTo(overlay, {
			alpha: 0,
		}, {
			duration: 1,
			alpha: 1,
			ease: 'expo',
		}, 0)
		.fromTo(close, {
			alpha: 0
		}, {
			alpha: 1,
			duration: 1,
			ease: 'expo'
		}, 0)

		
		const exits = [...u.qsa('.js-modal-close', currentVideo)]

		exits.forEach((exit) => {
			evt.on('click', exit, (e) => {
				e.preventDefault()
				this.closeVideo(currentVideo)
			})
		})
	}

	toggleVideo = (e) => {
		e.preventDefault()
		const current = e.currentTarget
		const modal = document.getElementById(current.dataset.modal)
		this.playVideo(modal)
	}

	addEvents() {
		const { btn, overlay, close } = this.DOM

		evt.on('click', btn, this.toggleVideo)
		//overlay && evt.on('click', overlay, this.closeVideo)
		//close && evt.on('click', close, this.closeVideo)
	}

	init() {
		this.addEvents()
	}
}
