import sniffer from 'sniffer'
import { u } from '@js/app/index'

export default {
  dom: {
    body: document.body,
  },
  bounds: {
    ww: window.innerWidth,
    wh: window.innerHeight,
    sw: document.querySelector('[data-router-wrapper]').getBoundingClientRect().width
  },
  flags: {
    locked: false,
    small: window.matchMedia('(max-width: 639px)').matches,
    hover: window.matchMedia('(hover: hover)').matches,
    windows: (["Win32", "Win64", "Windows", "WinCE"].indexOf(window.navigator.platform) !== -1),
    ...sniffer.getInfos()
  },

  addClasses: function() {
    sniffer.addClasses(this.dom.body)
    this.flags.windows && (this.dom.body.classList.add('is-windows'))
  }
}
