import Highway from '@dogstudio/highway/src/highway'
import { gsap } from 'gsap'
import { u, evt, store } from '@js/app/index'
import { SplitText } from '@js/lib/SplitText'
const { dom, flags } = store

function loadImage(src) {
	return new Promise(resolve => {
		const image = new Image()
		image.src = src
		image.onload = () => resolve()
		image.onerror = () => resolve()
	})
}

export default class extends Highway.Transition {
	in({ from, to, done }) {
        let routerView = document.querySelector('[data-router-view]');

		dom.body.classList.remove('is-loading')
	    window.scrollTo(0, 0)
	    from.remove()
        routerView.remove() // For whatever reason, the "from" variable breaks after the first use
	    done()

		const t = u.qs('#transition')
		const img = u.qs('.js-transition-image', to)
		const text = [...u.qsa('.js-transition-text')]
		const oSplit = new SplitText( u.qs( '.js-transition-title' ), { type: 'lines' }).lines
		const iSplit = new SplitText( oSplit, { type: 'lines' }).lines

		const tl = gsap.timeline({
			paused: true
		})

		gsap.killTweensOf(t)

		text && (gsap.set(text, {
			alpha: 0
		}))

		iSplit && (gsap.set(iSplit, {
			opacity: 0,
			y: 100,
		}))
		
		gsap.to(t, {
			duration: 0.5,
			ease: 'expo',
			opacity: 0
		})

		img && (tl.from(img, {
			duration: 1,
			scale: 1.1,
			ease: 'expo',
			alpha: 0
		}, 0))

		text && (tl.to(text, {
			duration: 2,
			ease: 'expo',
			stagger: 0.35,
			alpha: 1
		}, 0))

		oSplit && (tl.set(oSplit, { overflow: 'hidden' }))
        oSplit && (tl.to(iSplit, {
        	stagger: 0.1,
        	opacity: 1,
        	y: 0,
            duration: 1,
            ease: 'expo',
        }, 0))

 		img && (loadImage(img.src).then(() => tl.play()))
	}

	out({ from, done }) {
		dom.body.classList.add('is-loading')
		evt.emit('menu:close')

		const t = u.qs('#transition')
		t && gsap.killTweensOf(t)
		t && gsap.to(t, {
			duration: 0.5,
			ease: 'expo',
			opacity: 1,
			onComplete: () => { done() }
		})
	}
}
