import Highway from '@dogstudio/highway/src/highway'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { evt, u, store } from '@js/app/index'

import {
	Lazy,
	Hero,
	STrigger,
	Dropdown,
	Button,
	Video,
	Apply,
	Steps,
	Slider,
	ImageHover,
	Courses,
	Form,
	SimpleSlider,
	Gloss,
	SingleSchedule,
	MS, Mf
} from '@js/components'

const { dom, flags } = store
const { qs, qsa } = u

gsap.registerPlugin(ScrollTrigger)

export default class extends Highway.Renderer {
	initial() {
		store.addClasses()

		this.onEnter()
		//this.hs = new HeadScroll()
		window.onload = () => this.load()

		const btn = qs('.button--outline')
		btn && (this.btn = new Button(btn))
	}

	setup() {
		this.initial()
	}

	load() {
		//this.initChat()
		this.onEnterCompleted()

		gsap.to('#loader', {
			autoAlpha: 0,
			duration: .5,
			delay: .5,
			ease: 'power1'
		})
	}

	onEnter() {
		let wrapper = this.wrap; // Apparently, this is supposed to be automatically defined by @dogstudios/highway
		this.el = wrapper ? wrapper.lastElementChild : document.querySelector('[data-router-wrapper]');
		this.handleTheme()
	}

	onEnterCompleted() {
		this.handleLazy()
		this.handleDropdown()
		this.handleTrigger()
		this.handleVideos()
		this.handleApply()
		this.handleSteps()
		this.handleSlider()
		this.handleButtons()
		this.handleImageHover()
		this.handleTooltips()
		this.handleForm()
		this.handleSimple()
		this.handleSingleSchedule()
		this.handleMultiSelect()
		this.handleMf()
		this.handleGloss()
	}

	onLeave() {
		let wrapper = this.wrap; // Apparently, this is supposed to be automatically defined by @dogstudios/highway
		let target = wrapper ? wrapper.lastElementChild : document.querySelector('[data-router-wrapper]');

		const logo = qs('.nav')
		const whiteLogo = qs('#white-logo')
		const colorLogo = qs('#color-logo')


		const theme = qs('[data-theme]', target)
		dom.body.dataset.theme = theme

		if (theme == 'white') {
			logo.classList.add('is-white')
			whiteLogo.classList.add('logo-active')
			colorLogo.classList.remove('logo-active')

		} else {
			logo.classList.remove('is-white')
			colorLogo.classList.add('logo-active')
			whiteLogo.classList.remove('logo-active')
		}

		this.apply && this.apply.destroy()
	}

	onLeaveCompleted() {
		this.lazy && this.lazy.destroy()
		this.slider && this.slider.destroy()
		this.lineHero && this.lineHero.destroy()
		this.courses && this.courses.destroy()
		this.sliders && this.sliders.forEach(slider => slider.destroy())
		this.schedules && this.schedules.forEach(schedule => schedule.destroy())
		this.ms && this.ms.forEach(ms => ms.destroy())
		this.gloss && this.gloss.forEach(gloss => gloss.destroy())
		
		ScrollTrigger.getAll().forEach(inst => inst.kill())
	}

	initChat() {
		const script = document.createElement('script')

		script.src = 'https://app.activechat.ai/gateway/script/3d77f5c4-95dd-40a7-b52d-78b7ca75040e'
		script.id = 'ACCW_EMBED'

		qs('head').appendChild(script)
	}

	handleLazy() {
		const elems = [...qsa('[data-lazy-src]')]
		elems.length && (this.lazy = new Lazy(elems))
	}

	handleTrigger() {
		this.trigger = new STrigger()
	}

	handleApply() {
		const el = qs('#applyform')
		el && (this.apply = new Apply(el))
	}

	handleSteps() {
		const el = qs('.js-info')
		el && (this.steps = new Steps(el))
	}

	handleSlider() {
		const el = qs('.js-circlegraph')
		el && (this.slider = new Slider(el))
	}

	handleDropdown() {
		const el = qs('.js-tab')
		el && (this.dropdown = new Dropdown(el))
	}

	handleVideos() {
		const elems = [...qsa('.js-video')]
		elems.length && (this.video = new Video(elems))
	}

	handleButtons() {
		const elems = [...qsa('.js-bend')]
		elems.length && (this.button = new Button(elems))
	}

	handleImageHover() {
		const elems = [...qsa('.js-image-item')]
		elems.length && (elems.forEach(el => this.imageHover = new ImageHover(el) ))
	}

	handleTooltips() {
		const el = qs('[data-tooltip]')
	}

	handleCourses() {
		const el = qs('.js-courses')
		el && (this.courses = new Courses(el))
	}

	handleForm() {
		const el = qs('.js-form')
		el && (this.form = new Form(el))
	}

	handleSimple() {
		const elems = [...qsa('.js-simple-slider')]
		elems.length && (this.sliders = elems.map(el => new SimpleSlider(el)))
	}

	handleSingleSchedule() {
		const elems = [...qsa('.js-schedule-period')]
		elems.length && (this.schedules = elems.map(el => new SingleSchedule(el)))
	}

	handleMultiSelect() {
		const elems = [...qsa('.js-ms')]
		elems.length && (this.ms = elems.map(el => new MS(el)))
	}

	handleGloss() {
		const elems = [...qsa('[data-gloss]')]
		elems.length && (this.gloss = elems.map(el => new Gloss(el)))
	}

	handleMf() {
		const el = qs('.js-mf')
		el && (this.mf = new Mf(el))
	}

	isHomepage() {
		var path = window.location.pathname;
		return path.length <= 1;
	}

	handleTheme = (to = this.el) => {
		const logo = qs('.nav')
		const whiteLogo = qs('#white-logo')
		const colorLogo = qs('#color-logo')

		// If this is the homepage, use white. The automatic detection doesn't work for initial pageload of homepage
		const initialTheme = to.dataset.theme;
		const theme = this.isHomepage() ? 'white' : initialTheme;
		dom.body.dataset.theme = theme

		if (theme == 'white') {
			logo.classList.add('is-white')
			whiteLogo.classList.add('logo-active')
			colorLogo.classList.remove('logo-active')

		} else {
			logo.classList.remove('is-white')
			colorLogo.classList.add('logo-active')
			whiteLogo.classList.remove('logo-active')
		}
	}
}
