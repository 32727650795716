import gsap from 'gsap'
import { evt, u, store } from '@js/app/index'

const { qs, qsa, rect } = u
const { dom } = store

export default class {
  
  constructor(el) {
    this.el = el
    this.ref = qs('select', this.el)
    this.options = [...qsa('option', this.ref)]

    this.msid = this.el.id
    this.ddid = this.el.id + '-dd'
    
    this.select = {
      el: null,
      dd: null
    }
    
    this.state = {
      open: false
    }
    
    this.tl = gsap.timeline({ paused: true })
    
    this.init()
  }
  
  init() {
    this.createSelect()
    this.createDropdown()
    this.addEvents()
    this.togglePlaceholder()
    this.resize()
    this.addSelectedOptions()
  }

  // Add Selected Options
  addSelectedOptions() {
    const selected_options = qs('#selected_options');
    if (!selected_options) 
      return;

    const options = selected_options.value.split(',');
    this.opts.filter((opt) => options.includes(opt.val))
    .map((option) => {
      option.option.click();
    })
  }
  
  // Event listeners
  addEvents() {
    const { el, dd } = this.select

    evt.on('click', el, this.toggle)
    evt.on('click', dd, this.pick)
    evt.on('resize', this.resize)
    evt.on('click', document, this.hide)
    evt.on('scroll', this.resize)
  }
  
  // Create select
  createSelect() {
    this.select.el = document.createElement('div')
    this.select.el.classList.add('ms')
    this.select.el.dataset.placeholder = this.ref.dataset.placeholder
    
    this.el.appendChild(this.select.el)
  }
  
  // Create dropdown
  createDropdown() {
    this.select.dd = document.createElement('div')
    this.select.dd.classList.add('ms-dd') 
    this.select.dd.id = this.ddid
    
    dom.body.appendChild(this.select.dd)
    
    this.opts = this.options.map(sOpt => {
      const val = sOpt.value
      const text = sOpt.textContent
      
      // Create dropdown option
      const option = document.createElement('div')
      option.textContent = text
      option.dataset.val = val
      option.classList.add('ms-dd-opt')
      
      sOpt.dataset.parent == '' && option.classList.add('is-parent')
      
      this.select.dd.appendChild(option)
      
      // Create select option
      const sEl = document.createElement('div')
      sEl.classList.add('ms-opt')
      sEl.textContent = text
      sEl.addEventListener('click', this.removeOpt)
      
      return {
        sOpt, sEl, option, val, selected: false
      }
    })
  }
  
  // Position dropdown relative to select
  dropdownPosition() {
    const { dd } = this.select
    const { left, top, height } = rect(this.el)

    gsap.set(dd, {
      top: top + height, left
    })
  }
  
  // Toggle dropdown on select click
  toggle = ({ target }) => {
    if (target.classList.contains('ms-opt')) {
      this.dePick(target) 
    } else if (!this.state.open) {
      this.open()
    } else {
      this.close()
    }
    
    this.resize()
  }
  
  // Open dropdown
  open = () => {
    this.state.open = true
    
    const { dd } = this.select
    
    this.tl.clear()
    .to(dd, { 
      autoAlpha: 1,
      duration: .25,
      ease: 'power1'
    }, 0)
    .fromTo(dd, {
      scale: .75,
    }, {
      scale: 1,
      duration: .15,
      ease: 'back'
    }, 0)
    .play()
  }
  
  // Close dropdown
  close = () => {
    this.state.open = false
    
    const { dd } = this.select
    
    this.tl.clear()
    .fromTo(dd, {
      scale: 1,
    }, {
      scale: .75,
      duration: .15,
      ease: 'back'
    }, 0)
    .to(dd, { 
      autoAlpha: 0,
      duration: .15,
      ease: 'power1'
    }, 0)
    .play()   
  }

  hide = ({ target }) => {
    !target.closest(`#${this.msid}`) && !target.closest(`#${this.ddid}`) && this.close()
  }
  
  // Select option
  pick = ({ target }) => {
    const val = target.dataset.val
    
    if (!val) return
    
    const opt = this.opts.find(({ option }) => option === target)
    
    if (!opt) return
    
    const { el } = this.select
    
    if (opt.selected) {
      opt.selected = false
      opt.option.classList.remove('is-active')
      opt.sOpt.removeAttribute('selected')
      
      opt.sEl.remove()
    } else {
      opt.selected = true
      opt.option.classList.add('is-active')
      opt.sOpt.setAttribute('selected', 'selected')
      
      el.appendChild(opt.sEl)
      gsap.fromTo(opt.sEl, {
        scale: 0.5,
        alpha: 0,
      }, {
        scale: 1,
        alpha: 1,
        duration: .25,
        ease: 'back'
      })
    }
    
    this.togglePlaceholder()
    this.dropdownPosition()

    evt.emit('ms:change')
  }
  
  // De-select option
  dePick = (target) => {
    const ddOpt = this.opts.find(opt => opt.sEl === target)
    
    if (!ddOpt) return
    
    ddOpt.selected = false
    ddOpt.option.classList.remove('is-active')
    ddOpt.sOpt.removeAttribute('selected')

    ddOpt.sEl.remove()
    
    this.togglePlaceholder()
    this.dropdownPosition()

    evt.emit('ms:change')
  }
  
  // Get values from the native select
  getValues() {
    const values = this.options
    .filter(opt => opt.selected)
    .map(opt => opt.value)
    
    return values
  }
  
  togglePlaceholder() {
    this.getValues().length === 0
      ? this.select.el.classList.add('is-empty')
      : this.select.el.classList.remove('is-empty')
  }
  
  resize = () => {
    this.state.open && this.dropdownPosition()
  }

  removeEvents() {
    const { el, dd } = this.select

    evt.off('click', el, this.toggle)
    evt.off('click', dd, this.pick)
    evt.off('resize', this.resize)   
    evt.off('click', document, this.hide) 
  }

  destroy() {
    this.removeEvents()
    this.select.dd.remove()
  }
}
