import gsap from 'gsap'
import debounce from 'lodash.debounce'

import { evt, u, store } from '@js/app/index'

const { qs, qsa, rect } = u
const { dom } = store

export default class {
  ui = {}

  constructor(el) {
    this.el = el
    this.selects = qsa('select', this.el)
    this.options = [...qsa('option', this.el)]
    this.items = [...qsa('.js-mf-item')]
    this.input = qs('.js-mf-search', this.el)

    this.search = debounce(this.search, 100)

    this.init()
  }

  init() {
    this.getCache()
    this.addEvents()
    this.filter()
  }

  addEvents() {
    evt.on('change', this.selects, this.filter)
    evt.on('ms:change', this.filter)
    evt.on('keyup', this.input, this.search)
  }

  getCache() {
    this.cache = this.items.map(el => {
      const terms = JSON.parse(el.dataset.filters)
      const text = el.dataset.text

      return {
        el, terms, text,
        hidden: false,
        tHidden: false
      }
    })
  }

  filter = () => {
    const values = this.getValues()
    const pathname = window.location.pathname.toString();
    if (values.length == 0)
      window.history.pushState("","", pathname);
    else
      window.history.pushState("","", pathname + '?options=' + values.join(','));

    this.cache.forEach(c => {
      const found = this.compare(values, c.terms)

      if (found) {
        c.hidden = false
        c.el.classList.remove('is-hidden')
      } else {
        c.hidden = true
        c.el.classList.add('is-hidden')
      }
    })
  }

  compare = (values, terms) => {
    return values.every(val => terms.includes(val))
  }

  getValues = () => {
    const vals = this.options
    .filter(opt => opt.selected)
    .map(opt => opt.value)
    
    return vals
  }

  search = () => {
    const value = this.input.value.toLowerCase()

    this.cache.forEach(c => {
      if (c.hidden) return

      const found = c.text.includes(value)

      if (found) {
        c.tHidden = false
        c.el.classList.remove('is-dn')
      } else {
        c.tHidden = true
        c.el.classList.add('is-dn')
      }
    })
  }
}
