import Base from './Base'
import { u, store, evt } from '@js/app/index'

import {
	HeroSlider,
	HomeHero,
} from '@js/components'

const { dom, flags } = store

export default class extends Base {
	onEnter() {
		super.onEnter()
	}
  
	onLeave() { 
		super.onLeave()
	}

	onEnterCompleted() {
		super.onEnterCompleted()

		this.handleHero()
		this.handleHeroSlider()
	}

	onLeaveCompleted() {
		super.onLeaveCompleted()

        if (this.hh && this.hh != null) {
            this.hh.destroy()
        }
        if (this.heroSlider && this.heroSlider != null) {
            this.heroSlider.destroy()
        }
	}

	handleHero() {
		const el = document.querySelector('.js-home-hero')
		el && (this.hh = new HomeHero(el))

	}

	handleHeroSlider() {
		const el = document.querySelector('.swiper-container')
		el && (this.heroSlider = new HeroSlider(el))
	}
}
