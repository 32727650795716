import Base from './Base'
import { u, store, evt } from '@js/app/index'

import {
  Faq,
  SearchFilter
} from '@js/components'

const { dom, flags } = store
const { qs, qsa } = u

export default class extends Base {
	onEnter() {
		super.onEnter()
	}
  
	onLeave() { 
		super.onLeave()
	}

	onEnterCompleted() {
    super.onEnterCompleted()
    
    this.filter = new SearchFilter(qs('.js-plink-list'))
	}

	onLeaveCompleted() {
    super.onLeaveCompleted()
    
    this.filter && this.filter.destroy()
	}
}
