import { gsap } from 'gsap'
import { evt, u, store } from '@js/app/index'

export default class {
	constructor(el) {
		this.el = el

		this.DOM = {
			sections: [...u.qsa('.js-tab-item', el)],
			texts: [...u.qsa('.js-tab-text', el)],
		}

		this.init()
	}

	setup() {
		const { sections } = this.DOM

		sections.forEach((section, index) => {
			evt.on('click', section, () => {

				const text = this.DOM.texts[index]
				const close = u.qs('button', section)
				const tab = u.qs('.search-results__item', section)

				gsap.killTweensOf(text)

				this.DOM.texts.forEach((el) => {

					if (el != text && el.classList.contains('is-active')) {
						
						el.classList.remove('is-active')

						const close = u.qs('button', el.parentNode)
						const tabz = u.qs('.search-results__item', el.parentNode)

						tabz && tabz.classList.remove('is-active')
						close.classList.remove('is-active')
						
						gsap.to(el, {
							duration: 1,
							height: 0,
							ease: 'expo'
						})
					}
				})

				if (!text.classList.contains('is-active')) {
					text.classList.add('is-active')
					close.classList.add('is-active')
					section.classList.add('is-active')
					tab && tab.classList.add('is-active')

					gsap.set(text, {
						height: 'auto'
					})
					gsap.from(text, {
						duration: 1,
						height: 0,
						ease: 'expo'
					})
				}

				evt.on('click', close, () => {
					if(text.classList.contains('is-active')) {
						setTimeout(() => {
							text.classList.remove('is-active')
							close.classList.remove('is-active')
							section.classList.remove('is-active')
							tab && tab.classList.remove('is-active')

							gsap.to(text, {
								duration: 1,
								height: 0,
								ease: 'expo'
							})
						})
					}
				})
			})
		})
	}

	onResize = () =>  {
		this.DOM.sections.forEach((section, index) => {

		})
	}

	addEvents() {
		evt.on('resize', this.onResize)
	}

	init() {
		this.setup()
		this.addEvents()
	}
}
