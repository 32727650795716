import { gsap } from 'gsap'
import { evt, u, store } from '@js/app/index'

export default class {
	constructor(elems) {

		/**
		 * DOM Elements
		 */
		this.DOM = {
			links: elems,
		}

		/**
		 * STATE's
		 */
		this.STATE = {
			hover: false
		}

		/**
		 * COORDS
		 */
		this.COORDS = {
			open: [
				"M 0,10 L 10,10 L 10,10 C 10,10 10,10 5,10 C 0,10 0,10 0,10 Z",
				"M 0,10 L 10,10 L 10,10 C 10,10 10,5 5,5 C 0,5 0,10 0,10 Z",
				"M 0,10 L 10,10 L 10,0 C 10,0 10,0 5,0 C 0,0 0,0 0,0 Z"
			],
			close: [
				"M 10,10 L 10,0 C 10,0 10,0 5,0 C 0,0 0,0 0,0 L 0,10 Z",
				"M 10,10 L 10,0 C 10,0 10,5 5,5 C 0,5 0,0 0,0 L 0,10 Z",
				"M 10,10 L 10,10 C 10,10 10,10 5,10 C 0,10 0,10 0,10 L 0,10 Z"
			]
		}

		this.init()
	}

	toggle = ({ currentTarget }) => {
		const index = this.DOM.links.indexOf(currentTarget)
		const coords = this.COORDS
		const path = u.qs('path', currentTarget)
		const title = {
			first: u.qs('.button__title-first', currentTarget),
			last: u.qs('.button__title-last', currentTarget)
		}

		gsap.killTweensOf([path, title])

		if ( !this.STATE.hover ) {
			this.enter(path, coords.open, title)
		} else {
			this.leave(path, coords.close, title)
		}
	}


	enter = (path, coords, title) => {
		this.STATE.hover = true

		gsap
		.timeline()
		.set(path, { attr: { d: coords[0] } })
		.to(path, { duration: 0.5, attr: { d: coords[1] }, ease: 'expo' })
		.to(path, { duration: 0.5, attr: { d: coords[2] }, ease: 'expo' }, 0.15)
		.to(title.first, { yPercent: -50, opacity: 0, duration: 0.5, ease: 'expo' }, 0)
		.fromTo(title.last, { yPercent: -50, opacity: 0, }, { yPercent: -100, duration: 0.5, opacity: 1, ease: 'expo' }, 0.175)	
	}

	leave = (path, coords, title) => {
		this.STATE.hover = false

		gsap
		.timeline()
		.set(path, { attr: { d: coords[0] } })
		.to(path, { duration: 0.5, attr: { d: coords[1] }, ease: 'expo' })
		.to(path, { duration: 0.5, attr: { d: coords[2] }, ease: 'expo' }, 0.1)
		.to(title.last, { yPercent: 50, opacity: 0, ease: 'expo', duration: 0.5 }, 0)
		.to(title.first, { yPercent: 0, opacity: 1, ease: 'expo', duration: 0.5 }, 0.175)
	}

	addEvents() {
		const { links } = this.DOM

		evt.on('mouseenter', links, this.toggle)
		evt.on('mouseleave', this.DOM.links, this.toggle)
	}

	init() {
		this.addEvents()
	}
}
