import gsap from 'gsap'
import { evt, store } from '@js/app/index'

const { flags, dom, bounds } = store
const { isDevice } = flags

export default new (class {
	constructor() {
		const { ww, wh } = bounds

		this.target = 0
		this.pos = 0

		this.addEvents()
	}

	tick = () => {
		this.pos = window.scrollY

		evt.emit('tick', { pos: this.pos })
	}

	scroll = ({ y }) => {
		this.target = y
	}

	clamp() {
		this.target = gsap.utils.clamp(0, dom.scroll, this.target)
	}

	reset = () => {
		evt.emit('header:reset')
	}

	resize = () => {
		this.clamp()
	}

	addEvents() {
		gsap.ticker.fps(-1)
		gsap.ticker.add(this.tick)

		evt.on('scroll', this.scroll)
	}
})()
