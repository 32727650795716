import { gsap } from 'gsap'
import { evt, u, store } from '@js/app/index'
import { SplitText } from '@js/lib/SplitText'

export default class {
	constructor(el) {
		this.el = el
		this.circles = [...u.qsa('.circle', this.el)]

		this.DOM = {
			images: [...u.qsa('.circlegraph__images .image', this.el)],
			circleInner: [...u.qsa('.circle__inner', this.el)],
			circleText: [...u.qsa('.js-circlegraph-text', this.el)],
			next: u.qs('.circlegraph__arrow--next', this.el),
			prev: u.qs('.circlegraph__arrow--prev', this.el),
			texts: u.qs('.circlegraph__texts', this.el)
		}
		
		const rect = this.el.getBoundingClientRect()
		const space = (rect.width / 2) / this.circles.length
		const angleSize = (180 / this.circles.length)
		const degree = 90
		const totalLength = this.circles.length - 1

		this.total = this.circles.length

		this.STATE = {
			index: 0,
			current: 0,
			angle: -(((angleSize / totalLength) * this.circles.length) + degree),
			dotSpace: (angleSize / totalLength) * this.circles.length,
		}
	
		this.init()
	}
	
	setPoints() {
		for (let i = 0; i < this.circles.length; ++i) {
			const circle = this.circles[i]
			this.STATE.angle += this.STATE.dotSpace
			
			circle.style.transform = `rotate(${this.STATE.angle}deg)`
		}

		for (let i = 0; i < this.DOM.circleText.length; ++i) {
			const el = this.DOM.circleText[i]
            const elTarget = u.qs('p', el);
			const text = u.qs('.text__inner', el)
			const play = u.qs('.circlegraph__play-btn', el)
			const p = [...u.qsa('*', text)]
			const oSplit = new SplitText(elTarget, { type: 'lines' }).lines
			const iSplit = new SplitText(oSplit, { type: 'lines' }).lines

            if (oSplit) {
                gsap.set(oSplit, {overflow: 'hidden'})

                if (!el.classList.contains('is-active')) {
                    gsap.set(play, {
                        opacity: 0
                    })
                    gsap.set([p, oSplit], {
                        y: 30,
                        opacity: 0,
                        zIndex: 0
                    })
                } else {
                    gsap.set(play, {
                        opacity: 1
                    })
                    gsap.set([p, oSplit], {
                        y: 0,
                        opacity: 1,
                        zIndex: 1
                    })
                }
            }
		}
	}
	
	hideImage(img, current) {
        gsap.timeline()
            .set(img, { zIndex: 1 })
            .to(img, {
                duration: 1,
                ease: 'Expo.easeOut',
                opacity: 0,
                onComplete: () => gsap.set(img, { zIndex: 1 })
            })
	}

	hideText(text, current) {
		const t = u.qs('.text__inner', text)
		const p = [...u.qsa('*', t)]
		const play = u.qs('.circlegraph__play-btn', text)

		gsap.killTweensOf([play, p])

        gsap
        .timeline()
        .set(p, { zIndex: 1 })
        .to(p, {
        	stagger: 0.05,
            duration: 1,
            y: 30,
            ease: 'Expo.easeOut',
            opacity: 0,
            onComplete: () => {
            	gsap.set(p, { zIndex: 0 })
            }
        })
		if (play) {
			gsap.timeline().to(play, {
				duration: 1,
				opacity: 0,
				ease: 'Expo.easeOut',
			},0)
		}
	}

	showImage(img, current) {
        gsap
        .timeline()
    	.set(img, { zIndex: 0 })
        .to(img, {
        	opacity: 1,
            duration: 1,
            ease: 'Expo.easeOut',
            startAt: {
            	scale: 1.07
            },
            scale: 1
        })
	}

	showText(text, current) {
		const play = u.qs('.circlegraph__play-btn', text)
		const t = u.qs('.text__inner', text)
		const p = [...u.qsa('*', t)]

		gsap.killTweensOf([play, p])

		gsap
		.timeline()
		.set(p, { zIndex: 1 })
		.to(p, {
			stagger: 0.05,
			opacity: 1,
			y: 0,
				duration: 1,
				ease: 'Expo.easeOut',
		}, 0)
		.set(this.DOM.texts, {
			height: this.DOM.circleText[this.STATE.current].clientHeight,
		}, 0)
		if (play) {
			gsap.to(play, {
				duration: 1,
				opacity: 1,
				ease: 'Expo.easeOut',
			}, 0)
		}
	}
	
	changeSlide = ({ currentTarget }) => {
		this.STATE.current = this.circles.indexOf(currentTarget.parentNode)

		if ( this.STATE.current === this.STATE.index ) return false

		this.animate()

		this.STATE.index = this.STATE.current
	}

	animate = () => {
		if ( this.STATE.current === this.STATE.index ) return false

		this.circles.forEach(el => {
			el.classList.remove('is-active')
		})

		this.DOM.circleText.forEach(el => {
			el.classList.remove('is-active')
		})

		this.circles[this.STATE.current].classList.add('is-active')
		this.DOM.circleText[this.STATE.current].classList.add('is-active')

		const currentImage = this.DOM.images[this.STATE.index]
		const nextImage = this.DOM.images[this.STATE.current]

		const currentText = this.DOM.circleText[this.STATE.index]
		const nextText = this.DOM.circleText[this.STATE.current]

		// Kill Tweens
		gsap.killTweensOf([currentImage, nextImage, currentText, nextText])

		// Images
		this.hideImage(currentImage, this.circles[this.STATE.current])
		this.showImage(nextImage, this.circles[this.STATE.current])

		// Texts
		this.hideText(currentText, this.circles[this.STATE.current])
		this.showText(nextText, this.circles[this.STATE.current])

		this.STATE.index = this.STATE.current
	}


	next = () => {
		this.STATE.current = gsap.utils.wrap(0, this.total, this.STATE.current + 1)
		this.animate()
	}

	prev = () => {
		this.STATE.current = gsap.utils.wrap(0, this.total, this.STATE.current - 1)
		this.animate()
	}
	
	resize = () => {
		this.DOM.texts.style.height = `${this.DOM.circleText[this.STATE.current].clientHeight}px`
	}

	destroy() {
		const { prev, next, circleInner } = this.DOM

		evt.off('click', circleInner, this.changeSlide)
		evt.off('resize', this.resize)

		prev && evt.off('click', prev, this.prev)
		next && evt.off('click', next, this.next)
	}
	
	addEvents() {
		const { prev, next, circleInner } = this.DOM

		evt.on('click', circleInner, this.changeSlide)
		evt.on('resize', this.resize)

		prev && evt.on('click', prev, this.prev)
		next && evt.on('click', next, this.next)

	}
	
	init() {
		this.setPoints()
		this.addEvents()
	}
}
