import gsap from 'gsap'
import { evt, u, store } from '@js/app/index'

const { qs, qsa } = u

export default class {

  constructor(el) {
    this.el = el
    this.sections = [...qsa('.js-f-section', this.el)]
    this.items = [...qsa('.js-f-item', this.el)]

    this.lvlsSelect = qs('.js-f-levels', this.el)
    this.catsSelect = qs('.js-f-cats', this.el)
    this.input =  qs('.js-search', this.el)

    this.c = {
      sections: null,
      items: null
    }

    this.totalitems = this.items.length - 1

    this.init()
  }

  init() {
    this.getItemCache()
    this.getSectionCache()
    this.addEvents()
  }

  addEvents() {
    evt.on('change', this.lvlsSelect, this.lvlsFilter)
    evt.on('keyup', this.input, this.search)
  }

  getItemCache() {
    this.c.items = this.items.map((el, i) => {
      const terms = el.dataset.text
      const lvl = el.dataset.level
      const parent = el.closest('.js-f-section')

      return { el, parent, 
        strings: [lvl, terms],
        hidden: [false, false, false]
      }
    })
  }

  getSectionCache() {
    this.c.sections = this.sections.map((el, i) => {
      const childs = this.c.items.filter(({ parent }) => parent === el)
      const cat = el.dataset.cat

      return { el, childs, cat }
    })
  }

  lvlsFilter = ({ target }) => {
    this.filter(target.value, 1)
  }

  search = () => {
    this.filter(this.input.value, 2)
  }

  filter(value, i) {
    const { items, sections } = this.c
    const all = value === 'all'

    items.forEach(item => {
      const hide = !item.hidden.find(val => val === true)

      if (all && hide) {
        item.el.classList.remove('is-hidden')

        return
      }

      if (item.strings[i].includes(value)) {
        item.hidden[i] = false
        !hide && item.el.classList.remove('is-hidden')
      } else {
        item.el.classList.add('is-hidden')
        item.hidden[i] = true
      }
    })

    sections.length && sections.forEach(section => {
      const hasEntries = section.childs.find(({ hidden }) => hidden.find(val => val === true))

      if (hasEntries === undefined) {
        section.el.classList.add('is-hidden')
      } else {
        section.el.classList.remove('is-hidden')
      }
    })    
  }

  destroy() {
    evt.off('change', this.lvlsSelect, this.lvlsFilter)
    evt.off('keyup', this.input, this.search)

    this.c = null
  }
}
