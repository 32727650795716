/**
 * CSS
 */
import '@css/app.scss';

/**
 * Highway (Page-transitions)
 */
import Highway from '@dogstudio/highway/src/highway'

/**
 * Quicklink
 */
import Quicklink from 'quicklink/dist/quicklink.umd.js'
/**
 * Intersection Observer
 */
import 'intersection-observer'

/**
 * App instance
 */
import { evt, u, store } from '@js/app/index'
import './lib/redactor'
import './lib/glossmodal'

/**
 * Renderers
 */
import * as R from './renderers'

/**
 * Transitions
 */
import * as T from './transitions'

// Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
if (import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

/**
 * Scroll Restoration
 */
if ('scrollRestoration' in history) {
	history.scrollRestoration = 'manual'
}

import { Nav } from '@js/components'

/**
 * Highway initial
 */
Highway.initial = false

const { dom, flags } = store

let H

class App {
	constructor() {
		this.init()
	}

	initHighWay() {
		H = new Highway.Core({
			renderers: {
				default: R.Base,
				schedule: R.Schedule,
				faq: R.Faq,
				plink: R.Plink,
				home: R.Home
			},
			transitions: {
				default: T.Base,
			},
		})

		const links = [...u.qsa('#nav-list a')]

		Quicklink.prefetch(links)
		
		H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
			// Check Active Link
			for (let i = 0; i < links.length; i++) {
				const link = links[i];

				// Clean class
				link.classList.remove('is-active');

				// Active link
				if (link.href === location.href) {
				  link.classList.add('is-active');
				}
			}
		})

		H.on('NAVIGATE_END', ({ to, location }) => {

			Quicklink.listen({
				el: to.view
			})

			// Check Active Link
			for (let i = 0; i < links.length; i++) {
				const link = links[i];

				// Clean class
				link.classList.remove('is-active');

				// Active link
				if (link.href === location.href) {
				  link.classList.add('is-active');
				}
			}
		})

		window.H = H;
	}

	initNav() {
		const el = u.qs('#ham')
		el && (this.nav = new Nav(el))
	}

	init() {
		this.initHighWay()
		this.initNav()
	}
}

export { H }

new App()
