import Base from './Base'
import { u, store, evt } from '@js/app/index'

import {
  Schedule
} from '@js/components'

const { dom, flags } = store
const { qs, qsa } = u

export default class extends Base {
	onEnter() {
		super.onEnter()
	}
  
	onLeave() { 
		super.onLeave()
	}

	onEnterCompleted() {
    super.onEnterCompleted()
    
    this.schedule = new Schedule()
	}

	onLeaveCompleted() {
    super.onLeaveCompleted()
    
    this.schedule && this.schedule.destroy()
	}
}
