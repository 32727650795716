import gsap from 'gsap'

import { evt } from '@js/app/index'

export default new class {
    constructor() {
        evt.on('scroll', window, () => {
            evt.emit('scroll', { y: window.scrollY })
        })
    }
}
