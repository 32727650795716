import axios from 'axios'
import { evt, u, store } from '@js/app/index'
import Pristine from 'pristinejs'
import { gsap } from 'gsap'
//import { H } from '@js/app'
import ScrollToPlugin from 'gsap/ScrollToPlugin'
import { GlobalRaf } from '@js/app/plugins'

gsap.registerPlugin(ScrollToPlugin)

export default class {
	constructor(el) {
		this.form = el
		this.elems = [...u.qsa('input:invalid', this.el)]

		const defaultConfig = {
		    classTo: 'form__row',
		    errorClass: 'has-danger',
		    successClass: 'has-success',
		    errorTextParent: 'form__row',
		    errorTextTag: 'div',
		    errorTextClass: 'text-help' 
		}

		this.pristine = new Pristine(this.form, defaultConfig)

		this.init()
        this.H = window.H;
	}

	setProps() {

	}

	getStatus = (e) => {
		e.preventDefault()

		const valid = this.pristine.validate()
		const elems = [...u.qsa('.has-danger', this.form)]

		if (valid) {
	        const form = e.target
	        const data = new FormData(form)

			const method = form.getAttribute('method')
			const action = form.getAttribute('action')
		
			axios({
				url: action,
				method: method,
				data: data,
			    headers: {
			    	'X-CSRF-Token': window.csrfTokenValue,
			        'Content-Type': 'application/json'
			    }
			})
			.then(result => this.successFormHandler(form, result))
			.catch(error => this.errorFormHandler(error))
		} else {

			for (let i = 0; i < this.elems.length; i++) {
				const element = this.elems[i]

				if (element.value.length == 0) {
					element.dataset.state = 'invalid'
				}
			}

			gsap.to(window, {
				duration: 1,
				scrollTo: {
					y: (window.scrollY + elems[0].getBoundingClientRect().top),
					offsetY: 100
				},
				ease: 'power3.inOut'
			})
		}
	}

	/**
	 * Success
	 */
	successFormHandler(form, result) {
		const response = result.data

		form.reset()
		//alert('success!')
		this.H.redirect(window.location.origin + '/thanks/')
	}

	/**
	 * Error
	 */
	errorFormHandler(error) {
		console.log(error)
	}

	/**
	 * Destroy
	 */
	destroy() {
		evt.off('submit', this.form, this.getStatus)
		this.pristine.destroy()
	}

	/**
	 * ValidListener
	 */
	validListener = ({ currentTarget }) => {
		const value = currentTarget.value.trim()

		if (value) {
			currentTarget.dataset.state = 'valid'
		} else {
			currentTarget.dataset.state = 'invalid'
		}
	}

	/**
	 * Init
	 */
	init() {
		this.setProps()

		evt.on('submit', this.form, this.getStatus)
		evt.on('keyup', 'input', this.validListener)
		//evt.on('change', this.checkboxes, this.handleCheck)
	}
}
