import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import { evt, u, store } from '@js/app/index'

const { qs, qsa, checkImage } = u

export default class {
  constructor(elems) {
    this.elems = elems
    this.handle()
  }

  handle() {
    this.elems.forEach(el => {
      ScrollTrigger.create({
        trigger: el,
        start: '-50% bottom',
        once: true,
        onEnter: () => this.load(el)
      })
    })    
  }

  update = () => {
    this.elems = qsa('[data-lazy-src]')
    this.handle()
  }

  load(target) {
    const src = target.dataset.lazySrc

    checkImage(src).then(() => {
      target.src = src
    })
  }

  destroy() {
    this.elems = null
  }
}
