import gsap from 'gsap'
import { evt, u, store } from '@js/app/index'

const { qs, qsa } = u

export default class {

  constructor(el) {
    this.el = qs('.js-faq-list')
    this.elems = [...qsa('.js-item', this.el)]

    this.last = null
    this.current = null

    this.init()
  }

  init() {
    this.getCache()
  }

  getCache() {
    this.cache = this.elems.map(el => {
      const trigger = qs('.js-trigger', el)
      const content = qs('.js-content', el)

      gsap.set(content, { height: 0 })

      evt.on('click', trigger, this.toggle)

      return {
        el, content, trigger,
        open: false
      }
    })
  }

  toggle = ({ currentTarget }) => {
    this.last = this.current
    this.current = this.cache.find(({ trigger }) => trigger === currentTarget)

    if (!this.current) return

    this.current.open ? this.close() : this.open()
  }

  open(item = this.current) {
    item.open = true

    item.el.classList.add('is-active')

    gsap.to(item.content, {
      height: 'auto',
      duration: .5,
      ease: 'expo'
    })

    this.last 
      && (this.last !== this.current) 
        && this.close(this.last)
  }

  close(item = this.current) {
    item.open = false
    
    item.el.classList.remove('is-active')

    gsap.to(item.content, {
      height: 0,
      duration: .5,
      ease: 'expo'
    })
  }
  
  destroy() {
    this.cache.forEach(({ trigger }) => evt.off('click', trigger, this.toggle))
  }
}
