import { gsap } from 'gsap'
import { evt, u, store } from '@js/app/index'

export default class {
	constructor(el) {
		this.el = el
		this.btn = u.qs('#applyBtn')

		this.init()
	}

	toggleForm = () => {
		gsap.to(this.btn, {
			duration: 0.5,
			ease: 'expo',
			opacity: 0
		})
		gsap.to(this.el, {
			height: 'auto',
			ease: 'expo',
			duration: 2,
			y: -100
		})
	}

	destroy() {
		evt.off('click', this.btn, this.toggleForm)
	}

	init() {
		evt.on('click', this.btn, this.toggleForm)
	}
}
