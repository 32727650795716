import { evt, u, store } from '@js/app/index'
import { gsap } from "gsap";
import { MotionPathPlugin } from "gsap/MotionPathPlugin";

gsap.registerPlugin(MotionPathPlugin)

export default class {
	constructor(el) {
		this.el = el

		this.svgLine = u.qs('.line-svg', this.el)
		this.dots = [...u.qsa('.js-dot', this.el)]
		this.dotspace = [...u.qsa('.dotspace', this.el)]
		this.texts = [...u.qsa('.line-svg__text')]
		this.images = [...u.qsa('.js-line-images img')]
		this.titles = [...u.qsa('.js-hero-title')]
		this.heroRect = this.el.getBoundingClientRect()
		this.currentSlideID = 0

		this.rect = this.svgLine.getBoundingClientRect()

		this.STATE = {
			index: 0
		}

		this.dots[0].classList.add('is-active')

		this.hover = false

		this.init()
	}

	placeItems = () => {
		let alignment = 1 / this.dots.length
		let adjustment = 1 / (this.dots.length * 2)

		this.dots.forEach((el, i) => {
			const pos = (alignment * i) + adjustment

			gsap.set(el, {
			    motionPath: {
			        path: "#Shape",
			        align: "#Shape",
			        alignOrigin:[0.5, 0.5],
			        end: pos,
			    }
			})
		})

		this.dotspace.forEach((el, i ) => {
			const pos = (alignment * i) + adjustment

			gsap.set(el, {
			    motionPath: {
			        path: "#Shape",
			        align: "#Shape",
			        alignOrigin:[0.5, 0.5],
			        end: pos,
			    }
			})

			const { left, top } = u.rect(el)
			const dotRect = u.rect(this.dotspace[0]).width / 2
			const txt = this.texts[i]

			txt.style.left = `${left + dotRect}px`
			txt.style.top = `${top + (dotRect * 2)}px`
		})
	}

	nextSlide = (index) => {

		this.dots[index].classList.add('is-active')

		if ( index === this.STATE.index ) return false

		const currentText = this.texts[this.STATE.index]
		const nextText = this.texts[index]

		const currentPlayBtn = this.dots[this.STATE.index]

		const currentImage = this.images[this.STATE.index]
		const nextImage = this.images[index]

		const currentTitle = this.titles[this.STATE.index]
		const nextTitle = this.titles[index]

		this.STATE.index = index

		gsap.killTweensOf([currentText, nextText, currentImage, nextImage, currentTitle, nextTitle])
		this.showText(nextText, nextImage, nextTitle)
		this.hideText(currentText, currentImage, currentTitle)

		currentPlayBtn.classList.remove('is-active')

		this.autoPlay()
	}

	enter = ({ currentTarget }) => {
		this.hover = true

		const index = this.dotspace.indexOf(currentTarget)

		currentTarget.closest('.js-dot').classList.add('is-active')

		if ( index === this.STATE.index ) return false

		const currentText = this.texts[this.STATE.index]
		const nextText = this.texts[index]

		const currentPlayBtn = this.dots[this.STATE.index]

		const currentImage = this.images[this.STATE.index]
		const nextImage = this.images[index]

		const currentTitle = this.titles[this.STATE.index]
		const nextTitle = this.titles[index]

		this.STATE.index = index

		gsap.killTweensOf([currentText, nextText, currentImage, nextImage, currentTitle, nextTitle])
		this.showText(nextText, nextImage, nextTitle)
		this.hideText(currentText, currentImage, currentTitle)

		currentPlayBtn.classList.remove('is-active')
	}

	showText(text, img, title) {
		text.classList.add('is-active')

		gsap
		.timeline()
		.set(img, { zIndex: 1 })
		.set(text, { zIndex: 1 })
		.set(title, { zIndex: 1 })
		.to(text, {
			opacity: 1,
			duration: 1,
			ease: 'expo',
		})
        .to(img, {
        	opacity: 1,
            duration: 1,
            ease: 'Expo.easeOut',
        },0)
 		.to(title, {
			opacity: 1,
			duration: 1,
			ease: 'expo',
		}, 0)
	}

	hideText(text, img, title) {
		gsap
		.timeline()
		.set(img, { zIndex: 2 })
		.to(text, {
            duration: 1,
            ease: 'expo',
            opacity: 0,
            onComplete: () => {
            	text.classList.remove('is-active')
            	gsap.set(text, { zIndex: 1 })
            }
		})
        .to(img, {
            duration: 1,
            ease: 'Expo.easeOut',
            opacity: 0,
            onComplete: () => gsap.set(img, { zIndex: 1 })
        },0)
        .to(title, {
            duration: 1,
            ease: 'Expo.easeOut',
            opacity: 0,
            onComplete: () => gsap.set(title, { zIndex: 1 })
        },0)
	}

	destroy() {
		this.el = null

		evt.off('mouseenter', this.dotspace, this.enter)
		evt.off('resize', this.onResize)
	}

	onResize = () => {
		this.placeItems()
	}

	addEvents() {
		evt.on('mouseenter', this.dotspace, this.enter)
		evt.on('resize', this.onResize)
	}

	autoPlay = () => {
		if (this.hover) return

		let goToSlide = this.STATE.index + 1

		gsap.delayedCall(5, () => {
			if (goToSlide === this.dots.length ) {
				this.nextSlide(0)
			} else {
				this.nextSlide(goToSlide)
			}
		})
	}

	init() {
		this.placeItems()
		this.autoPlay()
		this.addEvents()
	}
}
