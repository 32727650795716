import gsap from 'gsap'
import { evt, u, store } from '@js/app/index'

const { qs, qsa } = u

export default class {

  constructor(el) {
    this.el = el
    this.sections = [...qsa('.js-f-section', this.el)]
    this.items = [...qsa('.js-f-item', this.el)]
    this.input =  qs('.js-search', this.el)

    this.c = {
      sections: null,
      items: null
    }

    this.totalitems = this.items.length - 1

    this.init()
  }

  init() {
    this.getItemCache()
    this.getSectionCache()
    this.addEvents()
  }

  addEvents() {
    evt.on('keyup', this.input, this.search)
  }

  getItemCache() {
    this.c.items = this.items.map((el, i) => {
      const terms = el.dataset.text
      const parent = el.closest('.js-f-section')

      return { el, parent, terms, hidden: false }
    })
  }

  getSectionCache() {
    this.c.sections = this.sections.map((el, i) => {
      const childs = this.c.items.filter(({ parent }) => parent === el)

      return { el, childs }
    })
  }

  search = () => {
    const value = this.input.value.toLowerCase()
    const { items, sections } = this.c

    items.forEach(item => {
      if (item.terms.includes(value)) {
        item.el.classList.remove('is-hidden')
        item.hidden = false
      } else {
        item.el.classList.add('is-hidden')
        item.hidden = true
      }
    })

    sections.length && sections.forEach(section => {
      const hasEntries = section.childs.find(({ hidden }) => hidden === false)

      if (hasEntries === undefined) {
        section.el.classList.add('is-hidden')
      } else {
        section.el.classList.remove('is-hidden')
      }
    })
  }

  destroy() {
    evt.off('keyup', this.input, this.search)

    this.c = null
  }
}
