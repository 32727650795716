import gsap from 'gsap'
import DrawSVGPlugin from '@js/lib/DrawSVGPlugin'
import ScrollTrigger from 'gsap/ScrollTrigger'

import { evt, u, store } from '@js/app/index'

const { qs, qsa } = u
const { flags } = store

gsap.registerPlugin(DrawSVGPlugin)

export default class {

  constructor(el) {
    this.el = el
    this.elems = [...qsa('.js-slide')]
    this.contents = qsa('.js-slide-content')
    this.labels = qsa('.js-slide-label')
    this.titles = qsa('.js-slide-title')
    this.texts = qsa('.js-slide-text')
    this.cursor = qs('.js-cursor', this.el)
    this.dots = [...qsa('.js-home-hero-dot', this.el)]
    this.triggers = qsa('[data-hero-trigger]')
    this.stroke = qs('.js-home-hero-stroke', this.el)

    this.last = 0
    this.current = 0
    this.total = this.elems.length
    this.z = this.total

    this.mouse = {
      x: 0, y: 0,
      current: {
        x: 0, y: 0
      }
    }

    this.tl = gsap.timeline({ paused: true })

    gsap.set(this.stroke, { drawSVG: 0 })

    this.init()
  }

  init() {
    this.getCache()
    this.addEvents()
    this.placement()
  }

  getCache() {
    this.cache = this.elems.map((el, i) => {
      const slides = [el, qs('img', el)]
      const content = this.contents[i]
      const label = this.labels[i]
      const title = this.titles[i]
      const text = this.texts[i]
      const texts = [label, title, text]
      
      let tl = gsap.timeline({ paused: true })

      return {
        el, slides, content, label, title, text, texts, tl
      }
    })
  }

  placement() {
    const alignment = 1 / this.dots.length
		const adjustment = 1 / (this.dots.length * 2)

    this.dots.forEach((el, i) => {
      const pos = (alignment * i) + adjustment

      gsap.set(el, {
        motionPath: {
          path: "#line",
          align: "#line",
          alignOrigin:[0.5, 0.5],
          end: pos,
        }
      })
    })
  }

  addEvents() {
    evt.on('click', this.el, this.next)

    if (flags.isDevice) {
      evt.on('click', this.dots, this.change)
    } else {
      evt.on('mouseenter', this.dots, this.change)
      evt.on('mousemove', this.move)
      evt.on('mouseenter', this.el, this.enter)
      evt.on('mouseleave', this.el, this.leave)
      evt.on('tick', this.run)
    }
  }

  next = ({ target }) => {
    if (target.closest('.js-home-hero-dot')) return

    this.last = this.current
    this.current = gsap.utils.wrap(0, this.total, this.current + 1)

    this.animate()
  }

  change = ({ currentTarget }) => {
    const i = this.dots.indexOf(currentTarget)

    this.last = this.current
    this.current = i

    this.animate()
  }

  toggleCircs() {
    this.dots[this.last].classList.remove('is-active')
    this.dots[this.current].classList.add('is-active')
  }

  animate = () => {
    if (this.last === this.current) return
    
    const l = this.cache[this.last]
    const c = this.cache[this.current]

    this.toggleCircs()

    this.z += 1

    gsap.to(this.stroke, {
      drawSVG: `${(100 / (this.total - 1)) * this.current}%`,
      duration: 1,
      ease: 'power3'
    })

    l.tl && l.tl.kill()
    l.tl = gsap.timeline()
    .to(l.texts, {
      y: '-3rem',
      alpha: 0,
      duration: .35,
      stagger: 0.05,
      ease: 'power2.inOut'
    }, 0)
    .set(l.content, { autoAlpha: 0 })    

    c.tl && c.tl.kill()
    c.tl = gsap.timeline()
    .set(c.content, { autoAlpha: 1 })
    .fromTo(c.texts, {
      y: '3rem',
      alpha: 0
    }, {
      y: 0,
      alpha: 1,
      duration: 1.5,
      stagger: 0.1,
      ease: 'expo'
    }, 0.25)

    gsap.timeline()
    .set(c.el, { zIndex: this.z })
    .to(c.el, {
      autoAlpha: 1,
      duration: .35,
      ease: 'power1'
    })
    .set(l.el, { autoAlpha: 0 })
  }

  move = ({ x, y }) => {
    if (!this.active) return

    this.mouse.x = x
    this.mouse.y = y
  }

  enter = () => {
    this.active = true

    this.tl && this.tl.kill()
    this.tl = gsap.timeline()
    .to(this.cursor, {
      alpha: 1,
      scale: 1,
      duration: .5,
      ease: 'expo',
    })
  }

  leave = () => {
    this.tl && this.tl.kill()
    this.tl = gsap.timeline()
    .to(this.cursor, {
      alpha: 0,
      scale: 0,
      duration: .5,
      ease: 'expo',
      onComplete: () => {
        this.active = false
      }
    })
  }

  run = ({ pos }) => {
    if (!this.active) return

    const { current, x, y } = this.mouse

    current.x += (x - current.x) * 0.1
    current.x = Math.round(current.x * 100) / 100

    current.y += (y - current.y) * 0.1
    current.y = Math.round(current.y * 100) / 100

    this.transform(current, pos)
  }

  transform({ x, y }, pos) {
    this.cursor.style.transform = `
      translate3d(${x}px, ${y + pos}px, 0)
    `
  }

  removeEvents() {
    evt.off('click', this.el, this.next)

    if (flags.isDevice) {
      evt.off('click', this.dots, this.change)
    } else {
      evt.off('mouseenter', this.dots, this.change)
      evt.off('mousemove', this.move)
      evt.off('mouseenter', this.el, this.enter)
      evt.off('mouseleave', this.el, this.leave)
      evt.off('tick', this.run)
    }
  }

  destroy() {
    this.removeEvents()

    this.mouse = null
    this.cache = null
  }
}
