import gsap from 'gsap'

import { evt, u, store } from '@js/app/index'

const{ qs, qsa } = u

export default class {

  constructor(el) {
    this.el = el
    this.slides = qs('.js-slides', this.el)
    this.elems = [...qsa('.js-slide', this.el)]

    this.btns = {
      prev: qs('.js-prev', this.el),
      next: qs('.js-next', this.el)
    }

    this.last = 0
    this.current = 0

    this.total = this.elems.length 
    this.z = this.total + 1

    this.init()
  }

  init(){
    this.addEvents()
    this.getCache()
  }

  addEvents() {
    const { prev, next } = this.btns

    evt.on('click', this.slides, this.next)
    evt.on('click', next, this.next)
    evt.on('click', prev, this.next)
  }

  getCache() {
    this.cache = this.elems.map(el => {
      return {
        el, img: qs('.js-slide-img', el)
      }
    })
  }

  next = () => {
    this.last = this.current
    this.current = gsap.utils.wrap(0, this.total, this.current + 1)

    this.z += 1

    this.animate()
  }

  prev = () => {
    this.last = this.current
    this.current = gsap.utils.wrap(0, this.total, this.current - 1)

    this.z += 1

    this.animate()
  }

  animate() {
    const c = this.cache[this.current]

    gsap.timeline()
    .set(c.el, { zIndex: this.z })
    .fromTo([c.el, c.img], {
      xPercent: gsap.utils.wrap([100, -75]),
    }, {
      xPercent: 0,
      duration: 1,
      ease: 'expo',
    })
  }

  removeEvents() {
    const { prev, next } = this.btns

    evt.off('click', this.slides, this.next)
    evt.off('click', next, this.next)
    evt.off('click', prev, this.next)
  }

  destroy() {
    this.removeEvents()
  }
}
