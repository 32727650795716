import gsap from 'gsap'

import { evt, u, store } from '@js/app/index'

const{ qs, qsa } = u

export default class {

  constructor(el) {
    this.el = el
    this.course = qs('.js-schedule-course', this.el)
    this.course && (this.courseOpts = qsa('option', this.course))

    this.type = qs('.js-schedule-type', this.el)
    this.type && (this.typeOpts = qsa('option', this.type))
    
    this.desc = qs('.js-schedule-desc', this.el)

    this.init()
  }

  init() {
    this.addEvents()
    this.course && this.changeType({ target: this.course })
  }

  addEvents() {
    this.course && evt.on('change', this.course, this.changeType)
    this.type && evt.on('change', this.type, this.changeDesc)
  }

  changeType = ({ target }) => {
    const index = target.selectedIndex + 1

    let first = null

    this.typeOpts.forEach((el, i) => {
      const course = parseFloat(el.dataset.course)
      const desc = parseFloat(el.dataset.desc)

      if (course === index) {
        el.style.display = 'block'
        !first && (this.type.selectedIndex = i, this.desc.selectedIndex = desc - 1)
        first = el
      } else {
        el.style.display = 'none'
      }
    })
  }

  changeDesc = ({ target }) => {
    const index = target.selectedIndex
    const opt = this.typeOpts[index]

    this.desc.selectedIndex = parseFloat(opt.dataset.desc) - 1
  }

  removeEvents() {
    this.course && evt.off('change', this.course, this.changeType)
    this.type && evt.off('change', this.type, this.changeDesc)
  }

  destroy() {
    this.removeEvents()
  }    
}
