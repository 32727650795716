import debounce from 'lodash.debounce'

import { evt, store } from '@js/app/index'

const { dom, bounds, flags } = store
const { isDevice } = flags

export default new (class {
  constructor() {
    this.setOrientation(dom, bounds)

    evt.on('resize', window, debounce(this.resize, 200))
    evt.on('orientationchange', window, this.resize)
  }

  resize = () => {
    const ww = window.innerWidth

    if (isDevice && ww === bounds.ww) return

    bounds.ww = ww
    bounds.wh = window.innerHeight

    this.setOrientation(dom, bounds)

    evt.emit('resize')
    isDevice && evt.emit('resize:on-reset')
  }

  setOrientation({ body }, { wh, ww }) {
    ww < wh
      ? body.classList.add('is-portrait')
      : body.classList.remove('is-portrait')
  }
})()
