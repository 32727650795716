import { gsap } from 'gsap'
import { evt, u, store } from '@js/app/index'

export default class {
	constructor(el) {

		this.DOM = {
			el: el,
			reveal: null,
			revealInner: null,
			revealImage: null,
		}

		this.STATE = {
			target: 0,
			current: 0,
			diff: 0,
			ease: 0.1,
			pos: 0
		}

		this.createElements()

		this.BOUNDS = {
			el: this.DOM.el.getBoundingClientRect(),
			reveal: this.DOM.reveal.getBoundingClientRect()
		}

		this.init()
	}

	createElements() {
		this.DOM.reveal = document.createElement('div')
		this.DOM.reveal.className = 'hover-reveal'
		this.DOM.reveal.style.transformOrigin = '0% 0%'

		this.DOM.revealInner = document.createElement('div')
		this.DOM.revealInner.className = 'hover-reveal__inner'
		this.DOM.revealImage = document.createElement('div')
		this.DOM.revealImage.className = 'hover-reveal__img'
		this.DOM.revealImage.style.backgroundImage = `url(${this.DOM.el.dataset.img})`

		this.DOM.revealInner.appendChild(this.DOM.revealImage)
		this.DOM.reveal.appendChild(this.DOM.revealInner)
		this.DOM.el.appendChild(this.DOM.reveal)
	}

	run = ({ pos }) => {

		gsap.set(this.DOM.reveal, {
			y: (this.BOUNDS.el.height / 2) - (this.BOUNDS.reveal.height / 2),
		})
	}

    enter = () => {
		evt.on('tick', this.run)

		this.showImage()
    }

    leave = () => {
		evt.off('tick', this.run)

		this.hideImage()
    }

    showImage = () => {
		gsap.killTweensOf([this.DOM.revealInner, this.DOM.revealImage])

		gsap
		.timeline({
			onStart: () => {
				this.DOM.reveal.style.opacity = this.DOM.revealInner.style.opacity = 1
				gsap.set(this.DOM.el, { zIndex: 5 })
			}
		})
		.to(this.DOM.revealInner, 0.6, {
			ease: 'Expo.easeOut',
			startAt: { scale: 0.6 },
			scale: 1
		})
		.to(this.DOM.revealImage, 0.6, {
			ease: 'Expo.easeOut',
			startAt: { scale: 1.4 },
			scale: 1
		}, 0)
    }

    hideImage = () => {
		gsap.killTweensOf([ this.DOM.revealInner, this.DOM.revealImage ])

		gsap.timeline({
			onStart: () => {
			    gsap.set(this.DOM.el, { zIndex: 1 })
			},
			onComplete: () => {
			    gsap.set(this.DOM.reveal, { opacity: 0 })
			}
		})
		.to(this.DOM.revealInner, 0.6, {
			ease: 'Expo.easeOut',
			scale: 0.6,
			opacity: 0
		})
		.to(this.DOM.revealImage, 0.6, {
			ease: 'Expo.easeOut',
			scale: 1.4
		}, 0)
    }

    addEvents() {
		evt.on('mouseenter', this.DOM.el, this.enter)
		evt.on('mouseleave', this.DOM.el, this.leave)
    }

	init() {
		this.addEvents()
	}
}
