import gsap from 'gsap'
import ScrollToPlugin from 'gsap/ScrollToPlugin'

import { evt, u, store } from '@js/app/index'

const{ qs, qsa } = u

gsap.registerPlugin(ScrollToPlugin)

export default class {

  constructor() {
    this.el = qs('.js-schedule')
    this.btn = qs('.js-schedule-scroll', this.el)
    this.selects = {
      cats: qs('.js-cats', this.el),
      needs: qs('.js-needs', this.el)
    }
    this.container = qs('.js-table-container')
    this.tables = [...qsa('.js-table')]
    this.cache = {
      opts: null,
      tables: null
    }

    this.init()
  }

  init() {
    this.addEvents()
    this.cacheOptions()
    this.cacheTables()
  }

  addEvents() {
    const { cats } = this.selects

    evt.on('change', cats, this.filterNeeds)
    evt.on('click', this.btn, this.changeSchedule)
  }

  cacheOptions() {
    this.cache.opts = [...qsa('.js-opt', this.selects.needs)].map(opt => {
      return {
        opt, cats: opt.dataset.cats,
        hidden: false
      }
    }).filter(({ cats }) => cats != undefined)
  }

  cacheTables() {
    this.cache.tables = this.tables.map(table => {
      return {
        table, slug: table.dataset.slug,
        hidden: false
      }
    })
  }

  filterNeeds = (e) => {
    const val = e.target.value
    console.log(val)
    const { needs } = this.selects

    needs.removeAttribute('disabled')
    
    this.cache.opts.forEach(c => {
      const exists = c.cats.includes(val)

      if (exists && c.hidden) {
        needs.appendChild(c.opt)
        c.hidden = false
      } else if (!exists) {
        c.opt.remove()
        c.hidden = true
      }
    })
  }

  filterTables = (val) => {
    this.cache.tables.forEach(c => {
      c.table.classList.remove('is-hidden')

      const exists = c.slug === val

      if (exists && c.hidden) {
        this.container.appendChild(c.table)
        c.hidden = false
      } else if (!exists) {
        c.table.remove()
        c.hidden = true
      }
    })
  }

  changeSchedule = () => {
    this.filterTables(this.selects.needs.value)
    this.scroll()
  }

  scroll = () => {
    gsap.to(window, {
      duration: 1,
      scrollTo: {
        y: '#mth-customize',
        offsetY: 100
      },
      ease: 'power3.inOut'
    })
  }

  removeEvents() {
    const { cats, needs } = this.selects

    evt.off('change', cats, this.filterNeeds)
    evt.off('change', needs, this.filterTables) 
    evt.off('click', this.btn, this.scroll)
  }

  destroy() {
    this.removeEvents()

    this.cache = null
  }    
}
