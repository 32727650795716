import { gsap } from 'gsap'
import { evt, u, store } from '@js/app/index'

export default class {
	constructor(el) {
		this.el = el

		this.info = [...u.qsa('.js-info-item', el)]
		this.step = [...u.qsa('.js-info-step', el)]

		this.info.forEach(el => {
			gsap.set(el, {
				opacity: 0
			})
		})

		this.init()
	}

	enter = ({ currentTarget }) => {
		console.log( currentTarget)
		this.step.forEach(el => {
			el.classList.remove('is-active')
		})
		
		this.info.forEach(el => {
			el.classList.remove('is-active')
		})
		
		const index = this.step.indexOf(currentTarget)
		const infoText = this.info[index]

		infoText.classList.add('is-active')
		currentTarget.classList.add('is-active')

		gsap.killTweensOf([currentTarget, infoText])

		gsap.to(currentTarget, {
			duration: 1,
			ease: 'expo',
			opacity: 1
		})

		gsap.to(infoText, {
			duration: 1,
			ease: 'expo',
			opacity: 1
		})
	}

	leave = ({ currentTarget }) => {
		this.info.forEach(el => {
			el.classList.remove('is-active')

			gsap.to(el, {
				duration: 1,
				ease: 'expo',
				opacity: 0
			})
		})

		currentTarget.classList.remove('is-active')
	}

	destroy() {
		evt.off('mouseenter', this.step, this.enter)
		evt.off('mouseleave', this.step, this.leave)
	}

	init() {
		evt.on('mouseenter', this.step, this.enter)
		evt.on('mouseleave', this.step, this.leave)
	}
}
