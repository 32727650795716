import gsap from 'gsap'
import { evt, u, store } from '@js/app/index'

export default class {
	constructor(el) {
		this.el = el

		this.menu = u.qs('.mobile-menu')
		this.menuInner = u.qs('.mobile-menu__inner')
		this.nav = u.qs('.nav')

		this.y = 100
		this.d = 0.75

		gsap.set(this.menuInner, {
			yPercent: this.y
		})

		this.init()
	}

	toggle = () => {
		gsap.killTweensOf([this.menu, this.menuInner])

		if (this.el.classList.contains('is-active')) {

			store.dom.body.classList.remove('oh')
			this.el.classList.remove('is-active')
			this.nav.classList.remove('is-active')

	        gsap.timeline()
	            .to(this.menu, {
	                duration: this.d,
	                ease: 'expo',
	                yPercent: 0,
	            }, 0)
	            .to(this.menuInner, {
	            	duration: this.d,
	            	ease: 'expo',
	            	yPercent: this.y,
	            }, 0)
		} else {
			store.dom.body.classList.add('oh')
			this.el.classList.add('is-active')
			this.nav.classList.add('is-active')

	        gsap.timeline()
	            .to(this.menu, {
	                duration: this.d,
	                yPercent: 100,
	                ease: 'expo',
	            }, 0)
	            .to(this.menuInner, {
	            	duration: this.d,
	            	ease: 'expo',
	            	yPercent: 0
	            }, 0)
		}
	}

	close = () => {
		if (this.el.classList.contains('is-active')) {
			gsap.killTweensOf([this.menu, this.menuInner])

			store.dom.body.classList.remove('oh')
			this.el.classList.remove('is-active')
			this.nav.classList.remove('is-active')

	        gsap.timeline()
	            .to(this.menu, {
	                duration: this.d,
	                ease: 'expo',
	                yPercent: 0,
	            }, 0)
	            .to(this.menuInner, {
	            	duration: this.d,
	            	ease: 'expo',
	            	yPercent: this.y
	            }, 0)
		}
	}

	init() {
		evt.on('click', this.el, this.toggle)
		evt.on('menu:close', this.close)
	}
}
